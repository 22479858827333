import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { notifications } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
const savedMoodValue = localStorage.getItem('moodValue')
console.log("--------------", savedMoodValue)

export const getAllNotifications = createAsyncThunk('frontNotifications/getAllNotifications', async () => {
  const response = await axiosRequest.get(notifications.FORNT_ALL_NOTIFICATION)
  return {
    data: response?.data?.data ?? [],
    unread_count: response?.data?.unread_count ?? 0
  }
})

export const getLatestNotifications = createAsyncThunk('frontNotifications/getLatestNotifications', async () => {
  const response = await axiosRequest.get(notifications.FORNT_Latest_NOTIFICATION)
  return {
    data: response?.data?.data ?? [],
    unread_count: response?.data?.unread_count ?? 0
  }
})

export const getupdateallnotifications = createAsyncThunk('frontNotifications/getupdateallnotifications', async (data) => {
  const response = await axiosRequest.post(notifications.FORNT_UPDATE_ALL_NOTIFICATION, data)
  return { data: response.data?.data ?? {} }
})

export const setAllNotifications = createAsyncThunk('frontNotifications/setAllNotifications', async (data) => {
  console.log('----setAllNotifications00--data--', data)
  return {
    data: data?.notifications ?? [],
    unread_count: data?.unRead ?? 0
  }
})
export const markNotificationAllRead = createAsyncThunk('frontNotifications/markNotificationAllRead', async ({ dispatch }) => {
  const response = await axiosRequest.get(notifications.FORNT_ALL_READ)
  console.log('--------response MarkNotificationAllRead----', response)
  if (response?.data?.status) {
    dispatch(getAllNotifications())
  }
  return {
    data: response?.data?.data ?? {},
    status: response?.data?.status ?? false
  }
})
export const deleteSingleNotification = createAsyncThunk('frontNotifications/deleteSingleNotification', async (id) => {
  console.log('----===deleteSingleNotification------data', { id })
  const response = await axiosRequest.post(notifications.FORNT_DELETE_SINGLE_NOTIFICATION, { id })
  console.log('===deleteSingleNotification---', response?.data)
  return {
    status: response?.data?.status ?? false
  }
})
export const deleteAllNotifications = createAsyncThunk('frontNotifications/deleteAllNotifications', async (id) => {
  const response = await axiosRequest.post(notifications.FORNT_DELETE_ALL_NOTIFICATION, id)
  console.log('===deleteAllNotifications---', response?.data)
  return {
    status: response?.data?.status ?? false
  }
})

export const getNotificationToken = createAsyncThunk('frontNotifications/getNotificationToken', async (token) => {
  console.log('----getNotificationToken----', token)
  const response = await axiosRequest.post(notifications.FORNT_NOTIFICATION_TOKEN, { token })
  console.log('--getNotificationToken Response-----', response)
  return {
    status: response?.data?.status ?? false,
    data: response?.data?.data ?? {}
  }
})

export const readAllChatNotifications = createAsyncThunk('frontNotifications/readAllChatNotifications', async (from, { dispatch }) => {
  const response = await axiosRequest.post(notifications.FORNT_READ_ALL_CHAT_NOTIFICATION, { from })
  console.log('--------response readAllChatNotifications----', response?.data)
  if (response?.data?.status) {
    dispatch(getAllNotifications())
  }
  return {
    status: response?.data?.status ?? false
  }
})


export const readSingleNotifications = createAsyncThunk('frontNotifications/readSingleNotifications', async (data) => {
  console.log('--------response readSingleNotifications---data-', data)

  const response = await axiosRequest.post(notifications.FORNT_READ_SINGLE_NOTIFICATION, data)
  console.log('--------response readSingleNotifications----', response?.data)
  return {
    status: response?.data?.status ?? false
  }
})

export const notificationsSlice = createSlice({
  name: 'frontNotifications',
  initialState: {
    notificaitonsData: [],
    latestNotificaitonsData: [],
    loadingLatestNotifications: false,
    unread_count: '',
    unread_count2: '',
    moodValue: savedMoodValue || "",
    value: null,
    loadingNotifications: true,
    // notifiToken: ''
    notifiToken: {}
  },
  reducers: {
    setData: (state, action) => {
      state.value = action.payload
      console.log("===setData============", state.value)
    },
     setMoodValue: (state, action) => {
          console.log("============== state before update: ", state.moodValue)
          console.log("============== payload: ", action.payload)
          state.moodValue = action.payload // Immer takes care of immutability.
          console.log("============== state after update: ", state.moodValue)
          localStorage.setItem('moodValue', action.payload)
        }
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {
        state.notificaitonsData = payload?.data ?? []
        state.unread_count = payload?.unread_count
        state.loadingNotifications = false
      })
      .addCase(getAllNotifications.pending, (state) => {
        console.log('LOADING...')
        state.loadingNotifications = true
      })
      .addCase(getAllNotifications.rejected, (state) => {
        console.log('REJECTED...')
        state.loadingNotifications = false
      })
      //
      .addCase(getLatestNotifications.fulfilled, (state, { payload }) => {
        state.latestNotificaitonsData = payload?.data ?? []
        state.unread_count2 = payload?.unread_count
        state.loadingLatestNotifications = false
      })
      .addCase(getLatestNotifications.pending, (state) => {
        console.log('LOADING...')
        state.loadingLatestNotifications = true
      })
      .addCase(getLatestNotifications.rejected, (state) => {
        console.log('REJECTED...')
        state.loadingLatestNotifications = false
      })
      .addCase(setAllNotifications.fulfilled, (state, { payload }) => {
        state.notificaitonsData = payload?.data ?? []
        state.unread_count = payload?.unread_count - 1
      })
      .addCase(deleteAllNotifications.fulfilled, (state, { payload }) => {
        console.log('----all chat notifications are read successfully', payload?.status)
      })
      .addCase(getNotificationToken.fulfilled, (state, { payload }) => {
        // state.notifiToken = payload?.data ?? ''
        state.notifiToken = payload?.data ?? {}
      })
      .addCase(readAllChatNotifications.fulfilled, (state, { payload }) => {
        console.log('----all chat notifications are read successfully', payload?.status)
      })
      .addCase(readSingleNotifications.fulfilled, (state, { payload }) => {
        console.log('---- readSingleNotifications are read successfully', payload?.status)
      })
      .addCase(deleteSingleNotification.fulfilled, (state, { payload }) => {
        console.log('----all chat notifications are read successfully', payload?.status)
      })
      .addCase(getupdateallnotifications.pending, (state) => {
        // Handle pending state here
        state.loading = true
      })
      .addCase(getupdateallnotifications.fulfilled, (state, { payload }) => {
        state.updateallnotifications = payload?.data ?? [] // Update your state accordingly
        state.loading = false
      })
      .addCase(getupdateallnotifications.rejected, (state, action) => {
        // Handle rejected state here
        console.error('Error in getupdateallnotifications:', action.error)
        state.loading = false
      })
  }
})
export const { setData, setMoodValue } = notificationsSlice.actions
export default notificationsSlice.reducer
