//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { groups } from '../../../../APIRoutes'

import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displayErrorToast, displaySuccessToast } from '../../../../utility/helper'
export const setAdminUsers = createAsyncThunk('adminUsers/setAdminUsers', async (data) => {
  return { data }
})

// Get All groups Detail
export const getFrontGroups = createAsyncThunk('frontGroups/getFrontGroups', async () => {
  const response = await axiosRequest.get(groups.FRONT_GROUPS)
  return {
    data: response?.data?.data,
    params: {
      perPage: 9,
      page: 1
    }
  }
})
// Get All Patients
export const getClinicPatients = createAsyncThunk('frontGroups/getClinicPatients', async () => {
  const response = await axiosRequest.get(groups.CLINIC_PATIENTS)
  return { data: response?.data?.data ?? [] }
})
//logs
export const updateLogs = createAsyncThunk('newDiscovery/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(groups.UPDATE_LOGS_GROUPS, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})

// Get Groups Details

export const getGroupsDetail = createAsyncThunk('frontGroups/getGroupsDetail', async (group_id) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_DETAIL, { group_id })
  return {
    groups: response?.data?.data?.group ?? {},
    posts: response?.data?.data?.posts ?? [],
    participants: response?.data?.data?.participants ?? [],
    post_count: response?.data?.data?.post_count ?? ''
  }
})

// Get Posts Details

export const getPostDetial = createAsyncThunk('frontGroups/getPostDetial', async (id) => {
  const response = await axiosRequest.post(groups.FRONT_POST_DETAILS, { id })
  return {
    postDetail: response?.data?.data?.post ?? {},
    postComments: response?.data?.data?.comments ?? [],
    comments_Count: response?.data?.data?.comments_count ?? ''
  }
})

//  unfavourite
export const unfavourite = createAsyncThunk('frontGroups/unfavourite', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_UNFAVOURITE, { group_id })
  if (response?.data?.status) {
    dispatch(getFrontGroups())
  }
  return {
    message: response?.data?.message ?? {}
  }
})
//  favourite
export const favourite = createAsyncThunk('frontGroups/favourite', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_FAVOURITE, { group_id })
  if (response?.data?.status) {
    dispatch(getFrontGroups())
  }
  return {
    message: response?.data?.message ?? {}
  }
})
//  Leave
export const leaveGroup = createAsyncThunk('frontGroups/leaveGroup', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_LEAVE, { group_id })
  if (response?.data?.status) {
    dispatch(getFrontGroups())
  }
  return {
    message: response?.data?.msg ?? {}
  }
})
//  Leave Post
export const leaveGrouponPost = createAsyncThunk('frontGroups/leaveGrouponPost', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_LEAVE, { group_id })
  if (response?.data?.status) {
    dispatch(getGroupsDetail(parseInt(group_id)))
  }
  return {
    message: response?.data?.msg ?? {}
  }
})
//  JOIN
export const joinGroup = createAsyncThunk('frontGroups/joinGroup', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_JOIN, { group_id })
  if (response?.data?.status) {
    dispatch(getFrontGroups())
  }
  return {
    message: response?.data?.message ?? {}
  }
})
//  JOIN On Post
export const joinGrouponPost = createAsyncThunk('frontGroups/joinGrouponPost', async (group_id, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_GROUP_JOIN, { group_id })
  if (response?.data?.status) {
    dispatch(getGroupsDetail(parseInt(group_id)))
  }
  return {
    message: response?.data?.message ?? {}
  }
})
// Invite Users
export const InviteUsers = createAsyncThunk('frontGroups/InviteUsers', async (invitationData) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_INVITE, invitationData)
  return {
    message: response?.data?.message ?? {}
  }
})
// Admin Users
export const getAdminUsers = createAsyncThunk('adminUsers/getAdminUsers', async (roleId) => {
  const response = await axiosRequest.post(groups.ADMIN_USERS, { roleid: roleId })
  console.log('--------response userdata-------', response?.data)
  return { data: response?.data?.data }
})

// Create New Group Post
export const createGroupPost = createAsyncThunk('frontGroups/createGroupPost', async ({ data, group_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_CREATE_GROUP_POST, data)
  console.log('--------response createGroupPost-------', response?.data)
  if (response?.data?.status) {
    dispatch(getGroupsDetail(parseInt(group_id)))
  }
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? 'Some Think Went Wrong'
  }
})
export const setGroupPost = createAsyncThunk('frontGroups/setGroupPost', async (data) => {
  return { data }
})
// Get Comments Details

export const getCommentsDetails = createAsyncThunk('frontGroups/getCommentsDetails', async (id) => {
  const response = await axiosRequest.post(groups.FRONT_COMMENTS_DETAILS, { id })
  return {
    data: response?.data?.data ?? {},
    comments: response?.data?.comments ?? [],
    participants: response?.data?.participants ?? []
  }
})

// Delete the Comments
export const deleteComment = createAsyncThunk('frontGroups/deleteComment', async ({ comment_id, post_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_DELETE_COMMENT, { comment_id })
  console.log('---------response-------', response?.data)
  if (response?.data?.status) {
    dispatch(getPostDetial(post_id))
  }
  return {
    status: response?.data?.status ?? ''
  }
})
// Submit The Comments of Challanges
export const addPostComment = createAsyncThunk('frontGroups/addPostComment', async (data) => {
  const response = await axiosRequest.post(groups.FRONT_ADD_POST_COMMENTS, data)
  return { message: response?.data?.message, status: response?.data?.status }
})
// Get Challenge Gallery

export const getChallengeGallery = createAsyncThunk('frontGroups/getChallengeGallery', async () => {
  const response = await axiosRequest.get(groups.FRONT_GET_CHALLENGE_GALLERY)
  return { data: response?.data?.data ?? [] }
})
// Get Challenge Complete Gallery

export const getChallengeCompleteGallery = createAsyncThunk('frontGroups/getChallengeCompleteGallery', async () => {
  const response = await axiosRequest.get(groups.FRONT_GET_CHALLENGE_COMPLETE_GALLERY)
  return { data: response?.data?.data ?? [] }
})
// CHALLENGE CATEGORIES
export const getCategories = createAsyncThunk('frontGroups/getCategories', async () => {
  const response = await axiosRequest.get(groups.FRONT_CHALLENGE_CATEGORIES)
  return { data: response?.data?.data ?? [] }
})

export const setCategories = createAsyncThunk('frontGroups/setCategories', async (data) => {
  return { data }
})

// Get All groups Detail
export const getFrontRecentPosts = createAsyncThunk('frontGroups/getFrontRecentPosts', async () => {
  const response = await axiosRequest.get(groups.FRONT_RECENT_POSTS)
  return { recentPosts: response?.data?.data ?? [] }
})
// LIKE POST
export const likePosts = createAsyncThunk('frontGroups/likePosts', async ({ post_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_POSTS_LIKE, { post_id })
  if (response?.data?.status) {
    dispatch(getFrontRecentPosts())
  }
  return {
    message: response?.data?.message ?? ''
  }
})
// UNLIKE POSTS
export const unLikePosts = createAsyncThunk('frontGroups/unLikePosts', async ({ post_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.FRONT_POSTS_UNLIKE, { post_id })
  if (response?.data?.status) {
    dispatch(getFrontRecentPosts())
  }
  return {
    message: response?.data?.message ?? ''
  }
})
// Update Employee Settings

// export const updateClinicEmployeeSetting = createAsyncThunk('frontGroups/updateClinicEmployeeSetting', async (data) => {
//     const response = await axiosRequest.post(meeting.CLINIC_EDIT_EMPLOYEES_SETTINGS, data)
//     return { status: response?.data?.status }
// })

// // Get Patient Statistics 

// export const getpatientStatistics = createAsyncThunk('frontGroups/getpatientStatistics', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_STATISTICS, userid)
//     console.log("======== Statistics Response=========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })

// // Get Patient Activity 

// export const getpatientActivity = createAsyncThunk('frontGroups/getpatientActivity', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_ACTIVITY, userid)
//     console.log("======== Activity Response =========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })

export const addForumComment = createAsyncThunk('frontGroups/addForumComment', async (data) => {
  const response = await axiosRequest.post(groups.POST_FORUM_COMMENT, data)
  return { message: response?.data?.message, status: response?.data?.status }
})

export const getForumComment = createAsyncThunk('frontGroups/getForumComment ', async (data, page) => {
  const response = await axiosRequest.post(groups.GET_FORUM_COMMENT, data, page)
  return { data: response?.data, status: response?.status, msg: response?.msg }
})

export const deleteForumComment = createAsyncThunk('frontGroups/deleteForumComment', async (data) => {
  const response = await axiosRequest.post(groups.DELETE_FORUM_COMMENT, data)
  return { message: response?.data?.message, status: response?.data?.status }
})

export const frontGroupsslice = createSlice({
  name: 'frontGroups',
  initialState: {
    clinicAllPatients: null,
    data: [],
    loading: true,
    frontAllGroups: [],
    totalGroups: 0,
    post_count: '',
    leavePostData: "",
    joinGroupData: "",
    joinPostData: "",
    leaveGroupData: "",
    challengeGallery: [],
    challangeCompleteGallery: [],
    categories: [],
    postDetail: {},
    postComments: [],
    comments_Count: '',
    groups: {},
    posts: [],
    participants: [],
    recentPosts: [],
    detailLoading: false,
    recentPostsLoading: false,
    patientLoading: false,
    isLoading: false,
    forumdata:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontGroups.fulfilled, (state, { payload }) => {
        state.frontAllGroups = payload?.data ?? []
        state.totalGroups = payload?.data.length
        state.params = payload?.params ?? {}
        state.groupsLoading = false
      })
      .addCase(getFrontGroups.pending, (state) => {
        state.groupsLoading = true
      })
      .addCase(getFrontGroups.rejected, (state) => {
        state.groupsLoading = false
      })
      .addCase(getCommentsDetails.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.comments = payload?.comments
        state.participants = payload?.participants ?? {}
      })
      .addCase(getGroupsDetail.fulfilled, (state, { payload }) => {
        state.groups = payload?.groups ?? {}
        state.posts = payload?.posts ?? []
        state.participants = payload?.participants ?? {}
        state.post_count = payload.post_count ?? ''
        state.detailLoading = false
      })
      .addCase(getGroupsDetail.pending, (state) => {
        state.detailLoading = true
      })
      .addCase(getGroupsDetail.rejected, (state) => {
        state.detailLoading = false
      })
      .addCase(getPostDetial.fulfilled, (state, { payload }) => {
        state.postDetail = payload?.postDetail
        state.postComments = payload?.postComments
        state.comments_Count = payload?.comments_Count
      })
      .addCase(setGroupPost.fulfilled, (state, { payload }) => {
        state.posts = payload?.data ?? []
      })
      //unfavourite
      .addCase(unfavourite.fulfilled, () => {
        displaySuccessToast('Group has been UnFavourite Successfully')
      })
      //favourite
      .addCase(favourite.fulfilled, () => {
        displaySuccessToast('Group has been Added To Favourite Successfully')
      })
      // Leave
      .addCase(leaveGroup.fulfilled, (state, { payload }) => {
        state.leaveGroupData = payload?.message ?? ''
        console.log("======112233 group", payload?.message)
      })
      // Leave on Post
      .addCase(leaveGrouponPost.fulfilled, (state, { payload }) => {
        state.leavePostData = payload?.message ?? ''
        console.log("======112233 post", payload?.message)

      })
      //Join
      .addCase(joinGroup.fulfilled, (state, { payload }) => {
        state.joinGroupData = payload?.message ?? ''
        console.log("======112233 post", payload?.message)
      })
      //Join On Post
      .addCase(joinGrouponPost.fulfilled, (state, { payload }) => {
        state.joinPostData = payload?.message ?? ''
        console.log("======112233 post", payload?.message)
      })
      //SUBMIT the Group Comments
      .addCase(addPostComment.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast(payload?.message)
        } else {
          displayErrorToast(payload?.message)
        }
      })
      //
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      // // Get Challenge Gallery
      .addCase(getChallengeGallery.fulfilled, (state, { payload }) => {
        state.challengeGallery = payload?.data ?? ''
      })
      // // Get Challenge Complete Gallery
      .addCase(getChallengeCompleteGallery.fulfilled, (state, { payload }) => {
        state.challangeCompleteGallery = payload?.data ?? ''
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(setCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(getFrontRecentPosts.fulfilled, (state, { payload }) => {
        state.recentPosts = payload?.recentPosts ?? []
        state.recentPostsLoading = false
      })
      .addCase(getFrontRecentPosts.pending, (state) => {
        state.recentPostsLoading = true
      })
      .addCase(getFrontRecentPosts.rejected, (state) => {
        state.recentPostsLoading = false
      })
      //LIKE Posts 
      .addCase(likePosts.fulfilled, () => {
        displaySuccessToast("You Like This Post")
      })
      //UNLIKE Posts  
      .addCase(unLikePosts.fulfilled, () => {
        displaySuccessToast("You UnLike This Post")
      })
      .addCase(createGroupPost.fulfilled, (state) => {
        displaySuccessToast('Post has been Add Successfully')
        state.recentPostsLoading = true
      })
      .addCase(createGroupPost.pending, (state) => {
        state.recentPostsLoading = false
      })
      .addCase(createGroupPost.rejected, () => {
       console.log('Rejected post loading')
      })
      .addCase(deleteComment.fulfilled, () => {
        displaySuccessToast('Comment has Been Deleted Successfully')
      })
       // Invite Users
       .addCase(InviteUsers.pending, () => {
        console.log(`Invite Users Pending`)
      })
      .addCase(InviteUsers.fulfilled, () => {
        // Handle fulfilled state
        console.log("Invitation sent successfully")
        displaySuccessToast('Invitations have been sent successfully')
      })
      .addCase(InviteUsers.rejected, (state, action) => {
        // Handle rejected state
        console.error("Invitation failed:", action.error.message)
      })
      .addCase(getAdminUsers.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminUsers.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminUsers.pending, (state) => {
        console.log('LOADING...')
        state.loading = true
      })
      .addCase(getClinicPatients.pending, () => {
        console.log("clinic patirnt pending", getClinicPatients.pending)
      })
      .addCase(getClinicPatients.rejected, () => {
        console.log("clinic patirnt pending", getClinicPatients.rejected)
      })
      .addCase(getClinicPatients.fulfilled, (state, { payload }) => {
        state.clinicAllPatients = payload?.data
      })
    //   // Get Patient Activity
    //   .addCase(getpatientActivity.fulfilled, (state, {payload}) => {
    //     state.patientActivity = payload?.data
    //     console.log("====== Patients Activity======", state?.patientActivity)
    // })

    .addCase(addForumComment.pending, (state) => {
      state.isLoading = true
    })
    .addCase(addForumComment.fulfilled, (state, action) => {
      state.isLoading = false
      state.forumdata = action.payload.data
    })
    .addCase(addForumComment.rejected, (state) => {
      state.isLoading = false
    })
    .addCase(getForumComment.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getForumComment.fulfilled, (state, action) => {
      state.isLoading = false
      state.forumdata = action.payload.data
    })
    .addCase(getForumComment.rejected, (state) => {
      state.isLoading = false
    })
    .addCase(deleteForumComment.pending, (state) => {
      state.loading = true
    })
    .addCase(deleteForumComment.fulfilled, (state, action) => {
      state.loading = false
      state.message = action.payload.message
      state.status = action.payload.status
    })
    .addCase(deleteForumComment.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }

})
export default frontGroupsslice.reducer