import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { statistics } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getAdminStatistics = createAsyncThunk('adminStatistics/getAdminStatistics', async (params) => {
  const response = await axiosRequest.post(statistics.ADMIN_STATISTICS_GRAPHS, params)
  return { data: response?.data?.data }
})

export const setAdminStatistics = createAsyncThunk('adminStatistics/setAdminStatistics', async (data) => {
  return { data }
})
//
// STATISTICS LOGS
export const getAdminStatisticsFeedBack = createAsyncThunk('adminStatistics/getAdminStatisticsFeedBack', async () => {
  const response = await axiosRequest.get(statistics.ADMIN_STATISTICS_FEEDBACK)
  return { data: response?.data?.data }
})
// STATISTICS LOGS
export const getAdminStatisticsLogs = createAsyncThunk('adminStatistics/getAdminStatisticsLogs', async (params) => {
  const response = await axiosRequest.post(statistics.ADMIN_STATISTICS_LOGS, params)
  return {
    data: response?.data?.data || [],
    message: response?.data?.message || "",
    status: response?.data?.status || false,
    total_logs: response?.data?.total_logs ?? 0,
    total_pages: response?.data?.total_pages ?? 0,
    total_records: response?.data?.total_records ?? 0
  }
})

export const setAdminStatisticsLogs = createAsyncThunk('adminStatistics/setAdminStatisticsLogs', async (data) => {
  return { data }
})

// DEMOGRAPHICS STATISTICS 
export const getAdminDemographicsStats = createAsyncThunk('adminStatistics/getAdminDemographicsStats', async (params) => {
  const response = await axiosRequest.post(statistics.ADMIN_STATISTICS_USER_DEMOGRAPHICS, params)
  return { data: response?.data?.data }
})

export const setAdminDemographicsStats = createAsyncThunk('adminStatistics/setAdminDemographicsStats', async (data) => {
  return { data }
})
// 
export const getUserStatistics = createAsyncThunk('adminStatistics/getUserStatistics', async (params) => {
  const response = await axiosRequest.post(statistics.ADMIN_STATISTICS_USERS, params)
  return { data: response?.data?.data }
})

export const adminStatisticsSlice = createSlice({
  name: 'adminStatistics',
  initialState: {
    loading: true,
    data: [],
    logsLoading: false,
    statsLogs: [],
    totalStatsLogs: 0,
    totalPages: 0,
    totalRecords: 0,
    feedbackGraphLoading: false,
    feedbackStatistics: [],
    demographicsLoading: true,
    demographicsStats: [],
    userStatistics: [],
    usersGraphLoading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminStatistics.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminStatistics.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminStatistics.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminStatistics.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      // STATISTICS LOGS
      .addCase(getAdminStatisticsLogs.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.statsLogs = payload?.data ?? []
        state.totalStatsLogs = payload?.total_logs ?? 0
        state.totalPages = payload?.total_pages ?? 0 
        state.totalRecords = payload?.total_records ?? 0 
        state.logsLoading = false
      })
      .addCase(setAdminStatisticsLogs.fulfilled, (state, { payload }) => {
        state.statsLogs = payload?.data ?? []
      })
      .addCase(getAdminStatisticsLogs.pending, (state) => {
        console.log("LOADING...")
        state.logsLoading = true
      })
      .addCase(getAdminStatisticsLogs.rejected, (state) => {
        console.log("REJECTED...")
        state.logsLoading = false
      })
      // DEMOGRAPHICS STATISTICS
      .addCase(getAdminDemographicsStats.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.demographicsStats = payload?.data ?? []
        state.demographicsLoading = false
      })
      .addCase(setAdminDemographicsStats.fulfilled, (state, { payload }) => {
        state.demographicsStats = payload?.data ?? []
      })
      .addCase(getAdminDemographicsStats.pending, (state) => {
        console.log("LOADING...")
        state.demographicsLoading = true
      })
      .addCase(getAdminDemographicsStats.rejected, (state) => {
        console.log("REJECTED...")
        state.demographicsLoading = false
      })
      // USERS STATISTICS
      .addCase(getUserStatistics.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.userStatistics = payload?.data ?? []
        state.usersGraphLoading = false
      })
      .addCase(getUserStatistics.pending, (state) => {
        console.log("LOADING...")
        state.usersGraphLoading = true
      })
      .addCase(getUserStatistics.rejected, (state) => {
        console.log("REJECTED...")
        state.usersGraphLoading = false
      })
      //
      .addCase(getAdminStatisticsFeedBack.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.feedbackStatistics = payload?.data ?? []
        state.feedbackGraphLoading = false
      })
      .addCase(getAdminStatisticsFeedBack.pending, (state) => {
        console.log("LOADING...")
        state.feedbackGraphLoading = true
      })
      .addCase(getAdminStatisticsFeedBack.rejected, (state) => {
        console.log("REJECTED...")
        state.feedbackGraphLoading = false
      })

  }
})

export default adminStatisticsSlice.reducer
