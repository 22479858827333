// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dashboard } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getDashboardData = createAsyncThunk('adminDashboard/getDashboardData', async () => {
  const response = await axiosRequest.get(dashboard.ADMIN_DASHBOARD)
  return {
    data: response?.data?.data
  }
})

export const setDashboardData = createAsyncThunk('adminDashboard/setDashboardData', async (data) => {
  return { data }
})

export const getDashboardUserActivity = createAsyncThunk('adminDashboard/getDashboardUserActivity', async (data) => {
  console.log('------getDashboardUserActivity---', data)
  const response = await axiosRequest.post(dashboard.ADMIN_DASHBOARD_USER_ACTIVITY, data)
  console.log('--------response--getDashboardUserActivity-', response?.data)
  return { max_click: response?.data?.max_click ?? '', data: response?.data?.data }
})


export const getDashboardUserActivityByRole = createAsyncThunk('adminDashboard/getDashboardUserActivityByRole', async (data) => {
  console.log('--------data---', data)
  const response = await axiosRequest.post(dashboard.ADMIN_DASHBOARD_USER_ACTIVITY_BY_ROLE, data)
  console.log('--------response---', response?.data)
  return { max_click: response?.data?.max_click ?? '', data: response?.data?.data }
})

export const getDashboardRoleWiseStatistics = createAsyncThunk('adminDashboard/getDashboardRoleWiseStatistics', async (data) => {
  console.log('--------data---', data)
  const response = await axiosRequest.post(dashboard.ADMIN_DASHBOARD_ROLEWISE_STATISTICS, data)
  console.log('---getDashboardRoleWiseStatistics-----response---', response?.data)
  return { data: response?.data?.data }
})

export const getDashboardClinicWiseStatistics = createAsyncThunk('adminDashboard/getDashboardClinicWiseStatistics', async (data) => {
  console.log('--------data---', data)
  const response = await axiosRequest.post(dashboard.ADMIN_DASHBOARD_CLINICWISE_STATISTICS, data)
  console.log('---getDashboardRoleWiseStatistics-----response---', response?.data)
  return { data: response?.data?.data }
})

export const dashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    data: [],
    dataClinic: [],
    datastats: [],
    loading: true,
    userActivity: [],
    userActivityByRole: [],
    maxClicks: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDashboardData.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setDashboardData.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getDashboardData.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getDashboardData.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      
      .addCase(getDashboardRoleWiseStatistics.fulfilled, (state, { payload }) => {
        state.datastats = payload?.data ?? []
      })
      .addCase(getDashboardClinicWiseStatistics.fulfilled, (state, { payload }) => {
        state.dataClinic = payload?.data ?? []
      })
      .addCase(getDashboardUserActivity.fulfilled, (state, { payload }) => {
        state.userActivity = payload?.data ?? []
        state.maxClicks = payload?.max_click ?? ''
      })
      .addCase(getDashboardUserActivityByRole.fulfilled, (state, { payload }) => {
        state.userActivity = payload?.data ?? []
        state.maxClicks = payload?.max_click ?? ''
      })
  }
})

export default dashboardSlice.reducer