import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_STATISTICS_GRAPHS: `${API_HOST}admin/statistics/graphs`,
  ADMIN_STATISTICS_LOGS: `${API_HOST}admin/statistics/logs`,
  ADMIN_STATISTICS_FEEDBACK: `${API_HOST}admin/tickets/get_feedback`,
  ADMIN_STATISTICS_USER_DEMOGRAPHICS: `${API_HOST}admin/statistics/user_demographics`,
  ADMIN_STATISTICS_USERS: `${API_HOST}admin/statistics/users_stats`,
  
  // CLINIC ROUTES 
  CLINIC_STATISTICS_GRAPHS: `${API_HOST}clinics/statistics/graphs`
}
